//
// $(document).ready() but without $.
//

import { Plan } from "@potat/plan/plan";
import { API } from "../api";

export function docReady(fn: () => void) {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

/**
 * Any function that registers with mapReady() receives the current map's
 * instance and an api client.
 */
export interface OnReadyArgs {
    plan: Plan;
    api: API;
}

//
// $(document).ready() but for the Leaflet map.
//
export function planReady(fn: (opts: OnReadyArgs) => void) {
    if (window.planState !== undefined && window.planState == "ready") {
        fn({ plan: window.plan, api: window.api });
    } else {
        document.addEventListener("potat:plan:ready", (event: CustomEvent) => {
            fn({ plan: event.detail.map, api: event.detail.api });
        });
    }
}
