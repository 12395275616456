/**
 * Common JS to all pages.
 */

// Initialize i18n and make translations available with global __("foobar")
// function.
import "./i18n";

// Initialize Sentry error reporting
import "./sentry";

import { Application, defaultSchema } from "@hotwired/stimulus";
import LocalizedInputController from "@potat/controllers/numeric_input_controller";
import Notification from "stimulus-notification";
import { ClipboardController } from "./controllers/clipboard_controller";
import { DatatableController } from "./controllers/datatable_controller";
import { DropdownController } from "./controllers/dropdown_controller";
import { FileDropController } from "./controllers/file_drop_controller";
import { LaunchModalController } from "./controllers/modal_controller";
import { TableSearchController } from "./controllers/tablesearch_controller";
import { TabsController } from "./controllers/tabs_controller";
import { TooltipController } from "./controllers/tooltip_controller";
import { docReady } from "./ui/doc-ready";
import { registerModalHandlers } from "./ui/modal";

docReady(registerModalHandlers);

/**
 * Setup StimulusJS controllers.
 *
 * NOTE: We explicitely register controllers instead of letting a bundler plugin
 * do that. These plugins have their own naming convention that is derived from
 * the controllers file name *and* location, creating a potential bug source
 * when moving files around.
 *
 * If registering Stimulus controllers by hand becomes too tedious, we can opt
 * for a plugin, see also:
 *  - https://stimulus.hotwired.dev/handbook/installing#using-webpack-helpers
 *  - https://github.com/zombiezen/esbuild-plugin-stimulus
 *
 */
docReady(() => {
    // Use a custom schema to not interfere with existing data attributes.
    const customSchema = {
        ...defaultSchema,
        controllerAttribute: "data-stimulus-controller",
        actionAttribute: "data-stimulus-action",
        targetAttribute: "data-stimulus-target",
    };

    const application = Application.start(document.documentElement, customSchema);

    if (__SENTRY__ && __SENTRY__.environment) {
        application.debug = __SENTRY__.environment !== "production";
    }

    window.Stimulus = application;

    window.Stimulus.register("launch-modal", LaunchModalController);
    window.Stimulus.register("notification", Notification);
    window.Stimulus.register("locale-input", LocalizedInputController);
    window.Stimulus.register("clipboard", ClipboardController);
    window.Stimulus.register("filedrop", FileDropController);
    window.Stimulus.register("tabs", TabsController);
    window.Stimulus.register("dropdown", DropdownController);
    window.Stimulus.register("datatable", DatatableController);
    window.Stimulus.register("tablesearch", TableSearchController);
    window.Stimulus.register("tooltip", TooltipController);
});
