import { Controller } from "@hotwired/stimulus";
import { Modal } from "@potat/ui/modal";

/**
 * Launch modals from any DOM element!
 *
 * Can launch modals that already exist in the DOM or create new modals from
 * scratch. Modals that exist in the DOM are referenced by their id attribute.
 * If you have dynamic content that goes into the modal, you can pass them to
 * the launcher, who in turn will pass it to the modal. To create a new modal,
 * simply omit the id attribute.
 *
 * Launch modal:
 *      <a data-stimulus-controller="launch-modal" data-modal-id-value="modal-id">
 *
 * Launch customized modal:
 *      <a data-stimulus-controller="launch-modal"
 *        data-launch-modal-id-value="modal-id"
 *        data-launch-modal-body-value="I am some content"
 *        data-launch-modal-confirm-url-value="..."
 *      >
 *
 * Launch a new modal (omit the id):
 *      <a data-stimulus-controller="launch-modal"
 *        data-launch-modal-header-value="A meaningful title"
 *        data-launch-modal-body-value="I am some content"
 *        data-launch-modal-theme-value="info"
 *      >
 *
 * You might have noticed that the controller has no data-stimulus-action. The
 * controller configures a default Stimulus action that launches modals when
 * clicking on the launcher.
 */
export class LaunchModalController extends Controller<HTMLElement> {
    static values = {
        /**
         * ID attribute of the target modal.
         */
        id: { type: String, default: null },

        /**
         * Optional text that goes into the modals title.
         * Will overwrite any existing content.
         */
        header: { type: String, default: null },

        /**
         * Optional text that goes into the modals body.
         * Will overwrite any existing content.
         */
        body: { type: String, default: null },

        /**
         * Optional URL to navigate to when clicking the confirm button.
         */
        confirmUrl: { type: String, default: null },

        /**
         * Optional text to display on the confirm button.
         */
        confirmText: { type: String, default: null },

        /**
         * Optional theme for new modals.
         */
        theme: { type: String, default: null },
    };

    declare idValue: string;
    declare bodyValue: string;
    declare headerValue: string;
    declare themeValue: string;
    declare confirmUrlValue: string;
    declare confirmTextValue: string;

    connect(): void {
        // Add default action to show modal when clicking the button.
        // NOTE: This will break if we change the Stimulus schema, i.e.,
        // changing the data-stimulus prefix.
        this.element.dataset.stimulusAction = "click->launch-modal#show";
    }

    show() {
        if (this.idValue) {
            Modal.show(this.idValue, {
                body: this.bodyValue,
                header: this.headerValue,
                confirmUrl: this.confirmUrlValue,
                onConfirm: () => {
                    this.dispatch("confirmed", {});
                },
            });
        } else {
            Modal.render({
                body: this.bodyValue,
                header: this.headerValue,
                theme: this.themeValue || "info",
                confirmText: this.confirmTextValue || _("Confirm"),
                confirmUrl: this.confirmUrlValue,
                onConfirm: () => {
                    this.dispatch("confirmed", {});
                },
            });
        }
    }
}
