/**
 * Configure Sentry error reporting.
 */
import * as Sentry from "@sentry/browser";

if (__SENTRY__ && __SENTRY__.enable) {
    const { dsn, environment } = __SENTRY__;

    Sentry.init({
        dsn: dsn,
        environment: environment,
        release: __RELEASE__,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 1,
    });

    console.debug(`Initialized Sentry SDK (environment=${environment})`);
} else {
    console.debug("Sentry not configured -- no error reporting.");
}
