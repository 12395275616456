import L from "leaflet";
export type VecLike = Vec2d | { x: number; y: number };

/**
 * 2-Dimensional vector helper.
 */

export class Vec2d {
    constructor(public x = 0, public y = 0) {}

    static fromCoordinate(latLng: L.LatLng) {
        return new Vec2d(latLng.lng, latLng.lat);
    }

    toCoordinate() {
        return L.latLng(this.y, this.x);
    }

    subtract(B: Vec2d): Vec2d {
        this.x -= B.x;
        this.y -= B.y;
        return this;
    }

    add(B: Vec2d): Vec2d {
        this.x += B.x;
        this.y += B.y;
        return this;
    }

    /**
     * Rotate coordinates around center of rotation.
     *
     * @param r - Angle to rotate in radians
     * @param center - Point of rotation
     * @returns this
     */
    rotateAround(r: number, center: VecLike) {
        const x = this.x - center.x;
        const y = this.y - center.y;
        const sin = Math.sin(r);
        const cos = Math.cos(r);

        this.x = center.x + (x * cos - y * sin);
        this.y = center.y + (x * sin + y * cos);

        return this;
    }

    /**
     * Computes angle of line spanned by vectors.
     */
    static Angle(A: VecLike, B: VecLike): number {
        return Math.atan2(B.y - A.y, B.x - A.x);
    }

    static Dist(A: VecLike, B: VecLike): number {
        return Math.hypot(A.y - B.y, A.x - B.x);
    }

    static RotateAround(r: number, a: VecLike, b: VecLike): Vec2d {
        return new Vec2d(a.x, a.y).rotateAround(r, b);
    }

    static Sub(A: Vec2d, B: Vec2d): Vec2d {
        return new Vec2d(A.x - B.x, A.y - B.y);
    }

    /**
     * Computes the slope (or steepness) of a line segment.
     *
     * @param A - Line segment start point.
     * @param B - Line segment endpoint.
     * @returns - Slope of line segment AB.
     */
    static Slope(A: Vec2d, B: Vec2d): number {
        const xDiff = A.x - B.x;

        if (xDiff === 0) {
            return 0;
        }

        return (A.y - B.y) / (A.x - B.x);
    }

    /**
     * Find nearest point on a line segment from another point.
     *
     * See also:
     * - https://jsfiddle.net/shishirraven/4dmjh0sa/
     * - https://jsfiddle.net/soulwire/UA6H5/
     *
     * @param A - Line segment start point
     * @param B - Line segment endpoint
     * @param P - Source point
     * @returns - Point on line segment AB that is closest to P.
     */
    static NearestPointOnLineSegment(A: Vec2d, B: Vec2d, P: Vec2d): Vec2d {
        const atob = { x: B.x - A.x, y: B.y - A.y };
        const atop = { x: P.x - A.x, y: P.y - A.y };
        const len = atob.x * atob.x + atob.y * atob.y;
        let dot = atop.x * atob.x + atop.y * atob.y;
        const t = Math.min(1, Math.max(0, dot / len));

        dot = (B.x - A.x) * (P.y - A.y) - (B.y - A.y) * (P.x - A.x);

        return new Vec2d(A.x + atob.x * t, A.y + atob.y * t);
    }
}
