import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export class DropdownController extends Controller<HTMLElement> {
    static values = { isExpanded: { type: Boolean, default: false } };
    declare isExpandedValue: boolean;

    readonly visibleClass = "is-active";

    connect() {
        useClickOutside(this);
    }

    /**
     * Toggle dropdown if the user clicks anywhere outside the dropdown.
     */
    clickOutside() {
        if (this.isExpandedValue) {
            this.isExpandedValue = false;
        }
    }

    toggle() {
        this.isExpandedValue = !this.isExpandedValue;
    }

    isExpandedValueChanged() {
        this.element.classList.toggle(this.visibleClass, this.isExpandedValue);
    }
}
