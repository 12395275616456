import { Controller } from "@hotwired/stimulus";
import tippy, { Placement, Instance as TippyInstance } from "tippy.js";
import "tippy.js/dist/tippy.css";

/**
 * Show tooltips using tippy.js
 */
export class TooltipController extends Controller {
    static values = { message: String, placement: { type: String, default: "top" } };
    declare messageValue: string;
    declare placementValue: Placement;

    tooltip: TippyInstance;

    connect() {
        this.tooltip = tippy(this.element, {
            content: this.messageValue,
            placement: this.placementValue,
        });
    }

    disconnect(): void {
        this.tooltip.destroy();
    }
}
