import { Controller } from "@hotwired/stimulus";
import DataTables, { Api as DatatableApi, Order } from "datatables.net";

/**
 * Integrate Datatables.net
 *
 * You can configure the table to be sorted on initialization using the `order`
 * controller value. Use Datatable's syntax of nested arrays to order, see:
 * https://datatables.net/reference/option/order
 *
 * Example: Sort the first column descending:
 *
 *  <table ... data-datatable-order-value='[[1, "desc"]]' />
 *
 * NOTE: Stimulus encodes value as JSON, any string in the array requires
 * double-quotes.
 *
 * For now, we only use Datatables sorting and searching features. We use
 * Datatable directly via its API instead of the provided controls.
 *
 */
export class DatatableController extends Controller<HTMLTableElement> {
    static values = {
        // Configure automatic ordering when table is initialized. Use
        // Datatables syntax of nested arrays, see:
        order: Array,
        // Selector to update with the number of records that are currently
        // displayed.
        info: String,
    };

    declare orderValue: Order[];
    declare infoValue: string;

    // TODO: Find a better way to reference and update these elements.
    infoElement: HTMLElement | null;

    datatableInst: DatatableApi;

    initialize(): void {
        const config: any = {
            // Only display [t]able - we are not interested in any other
            // Datatables.net provided control.
            dom: "t",
            // Disable paging, show all projects at once
            paging: false,
            // Disable info panel "Showing y records of x"
            info: true,
            // Enable searching; We hide the search box using the `dom` option
            // - setting searching to false would also hide it, but would also
            // disable the search() functioniality.
            searching: true,
            // Do not mess with our columns' width
            autoWidth: false,
            language: {
                zeroRecords: _("No matching records found."),
                emptyTable: _("No data available in table."),
            },
        };

        if (this.orderValue) {
            // Sort columns on load, if configured.
            config.order = this.orderValue;
        }

        // DataTables will replace the table element upon initialization,
        // causing the controller to disconnect and connect again.
        this.datatableInst = new DataTables(this.element, config);

        this.infoElement = document.querySelector(this.infoValue + " span");
    }

    /**
     * Returns number of records currently displayed.
     */
    get showing(): number {
        return this.datatableInst.page.info().recordsDisplay;
    }

    search(term: string) {
        this.datatableInst.search(term).draw();

        if (this.infoElement) {
            this.infoElement.textContent = `(${this.showing})`;
        }
    }
}
