/**
 * i18n support for JavaScript components with i18next.
 *
 * Shares localization database with the backend (i.e., the .po files). All
 * translatable strings in any JavaScript / TypeScript file is extracted and
 * merged with the existing localization data. However, we can not directly
 * read the .po files in JavaScript and require the .po file to be translated
 * to JSON, so we can embed it in the bundle.
 *
 * To provide a similar API as we do in Python, we expose a global function
 * `_`, i.e., `_("my translation string")`.
 *
 * For more information check out i18next docs:
 *  - https://www.i18next.com/
 *
 */
import i18next from "i18next";

// Embed localization database in bundle
import de from "../../../locale/de/LC_MESSAGES/potat.po.json";

i18next.init({
    lng: "de",
    debug: true,
    // allows keys to be phrases that contain colons (:) or dots (.)
    nsSeparator: false,
    keySeparator: false,
    // use the key as fallback
    fallbackLng: false,
    // ignore empty fields and use key as fallback
    returnEmptyString: false,
    resources: {
        de: {
            translation: de,
        },
    },
});

window._ = i18next.t;
