import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";
import { DatatableController } from "./datatable_controller";

/**
 * Search records in a data table.
 *
 * Define the table(s) you want to search as Stimulus outlets. This controller
 * can search multiple data tables at once.
 */
export class TableSearchController extends Controller<HTMLInputElement> {
    static debounces = ["search"];

    static values = { searchTerm: String };
    declare searchTermValue: string;

    static outlets = ["datatable"];
    declare datatableOutlets: DatatableController[];

    initialize() {
        // Wait for the user to finish typing to avoid refreshing the content
        // on every keystroke. Use { wait: timeoutMs } to adjust the timeout.
        useDebounce(this, { wait: 200 });

        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("filter")) {
            this.searchTermValue = searchParams.get("filter")!;
            this.element.value = this.searchTermValue;
        }
    }

    search() {
        this.searchTermValue = this.element.value;
    }

    searchTermValueChanged() {
        this.datatableOutlets.forEach((datatable) =>
            datatable.search(this.searchTermValue)
        );

        this.updateHistory();
    }

    /**
     * Update URL to make filter "shareable"
     */
    private updateHistory() {
        const filter =
            this.searchTermValue && this.searchTermValue.length > 0
                ? `?filter=${this.searchTermValue}`
                : window.location.pathname;

        window.history.replaceState(null, "", filter);
    }
}
