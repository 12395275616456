import L from "leaflet";
import { unpackCoords } from "../helpers";
import { Vec2d, VecLike } from "./vec2d";

/**
 * Any shape or coordinate(s) that can be transformed.
 */
type Transformable = L.Polygon | Vec2d[] | Vec2d | L.LatLng[] | L.LatLng[][];

interface TransformOptions {
    mutate: boolean;
}

const defaultTransformOptions: TransformOptions = {
    mutate: false,
};

/**
 * Translate along X- and Y-axis.
 */
export function translateXY<T extends Transformable>(
    shape: T,
    distance: VecLike,
    options: TransformOptions = defaultTransformOptions
): T {
    const transform = Transform.for(shape).translateXY(distance);

    if (options.mutate) {
        transform.mutate();
        return shape;
    }

    return transform.get();
}

/**
 * Rotate shape around the location of another point.
 *
 * Positive radians rotate shape in counterclockwise (ccw) direction, negative
 * radians in clockwise direction.
 */
export function rotateAround<T extends Transformable>(
    shape: T,
    point: Vec2d,
    radians: number,
    options: TransformOptions = defaultTransformOptions
): T {
    const transform = Transform.for(shape).rotateAround(point, radians);

    if (options.mutate) {
        transform.mutate();
        return shape;
    }

    return transform.get();
}

/**
 * Collection of geometric transformations.
 *
 * Can transforms a number of different shapes and data structure as
 * represented by `Transformable`. To extend the shapes that can be
 * transformed, add it to the type and create a subclass that handles it (or
 * extend an existing one).
 *
 * This abstract base class implements all transformations. To do that, we
 * represent any shape internally as an (flat) array of points. Any subclass
 * must convert its data to Vec2d[] on creation and back to its structure when
 * apply()ing.
 *
 * Transformations can be chained, i.e., multiple transformations can be
 * applied at once, e.g.,
 *
 *      // Translate shape by 2 units along X axis
 *      Transform.for(shape)
 *          .translate({x: 1, y: 0})
 *          .translate({x: 1, y: 0})
 *          .get()
 *
 * To retrieve transformed shape, you must call `apply()`.
 */
export abstract class Transform<T extends Transformable> {
    points: Vec2d[];
    matrix: TransformationMatrix;
    readonly shape: T;

    constructor(transformable: T) {
        this.shape = transformable;
        this.matrix = new TransformationMatrix();
    }

    /**
     * Create Transform instance based on the type of shape.
     */
    static for<T extends Transformable>(transformable: T): Transform<T> {
        if (isPolygon(transformable)) {
            return new TransformPolygon(transformable) as Transform<T>;
        } else if (isLatLngArray(transformable) || isNestedLatLngArray(transformable)) {
            return new TransformLatLngs(transformable) as Transform<T>;
        } else if (isPoint(transformable) || isPointArray(transformable)) {
            return new TransformPoints(transformable) as Transform<T>;
        } else {
            throw new Error("Can not transform unknown shape.");
        }
    }

    /**
     * Get transformed shape.
     *
     * Does not mutate the original shape, returns a transformed copy instead.
     * The returned shape's format matches the input shape.
     */
    abstract get(): T;

    /**
     * Apply transformation to the original shape.
     */
    abstract mutate(): void;

    /**
     * Translate shape by distance in X and Y direction.
     */
    translateXY(distance: VecLike) {
        const matrix = new TransformationMatrix();
        this.points = matrix.translate(distance.x, distance.y).apply(this.points);
        return this;
    }

    /**
     * Rotate shape around another point.
     *
     * https://gamefromscratch.com/gamedev-math-recipes-rotating-one-point-around-another-point/
     */
    rotateAround(point: Vec2d, radians: number) {
        this.points.map((p) => p.rotateAround(radians, point));
        return this;
    }

    protected applyMatrix() {
        return this.matrix.apply(this.points);
    }
}

class TransformPolygon extends Transform<L.Polygon> {
    constructor(polygon: L.Polygon) {
        super(polygon);
        this.points = unpackCoords(polygon).map((k) => Vec2d.fromCoordinate(k));
    }

    get(): L.Polygon<any> {
        const transformed = this.applyMatrix();
        const latLngs = transformed.map((vec) => vec.toCoordinate());
        return L.polygon([latLngs]);
    }

    mutate(): void {
        const transformed = this.get();
        this.shape.setLatLngs(transformed.getLatLngs());
    }
}

class TransformLatLngs extends Transform<L.LatLng[] | L.LatLng[][]> {
    constructor(latLngs: L.LatLng[] | L.LatLng[][]) {
        super(latLngs);

        let coords: L.LatLng[];

        if (isNestedLatLngArray(this.shape)) {
            coords = latLngs[0] as L.LatLng[];
        } else {
            coords = latLngs as L.LatLng[];
        }

        this.points = coords.map(Vec2d.fromCoordinate);
    }

    get(): L.LatLng[] | L.LatLng[][] {
        const transformed = this.applyMatrix();
        const coords = transformed.map((vec) => vec.toCoordinate());

        return isNestedLatLngArray(this.shape) ? [coords] : coords;
    }

    mutate(): void {
        let transformed = this.get();
        transformed = isNestedLatLngArray(transformed) ? transformed[0] : transformed;

        const shapes = isNestedLatLngArray(this.shape) ? this.shape[0] : this.shape;

        for (let index = 0; index < transformed.length; index++) {
            shapes[index].lng = this.points[index].x;
            shapes[index].lat = this.points[index].y;
        }
    }
}

class TransformPoints extends Transform<Vec2d | Vec2d[]> {
    constructor(points: Vec2d | Vec2d[]) {
        super(points);

        if (isPoint(points)) {
            points = [points];
        }

        this.points = points.map((p) => new Vec2d(p.x, p.y));
    }

    get(): Vec2d | Vec2d[] {
        if (isPoint(this.shape)) {
            return this.points[0];
        }

        return this.points;
    }

    mutate(): void {
        const shapes = isPoint(this.shape) ? [this.shape] : this.shape;

        for (let index = 0; index < shapes.length; index++) {
            shapes[index].x = this.points[index].x;
            shapes[index].y = this.points[index].y;
        }
    }
}

/**
 * Transform points using a 2D transformation matrix.
 *
 * Does not implement all possible transforms (yet), adding operations when required.
 *
 * Resources:
 *
 * - https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/Matrix_math_for_the_web
 * - https://www.alanzucconi.com/2016/02/10/tranfsormation-matrix/
 * - https://github.com/Fionoble/transformation-matrix-js
 *
 */
class TransformationMatrix {
    // Scale X
    a: number;
    // Skew Y
    b: number;
    // Skew X
    c: number;
    // Scale Y
    d: number;
    // Translate X
    e: number;
    // Translate Y
    f: number;

    constructor() {
        this.a = 1;
        this.b = 0;
        this.c = 0;
        this.d = 1;
        this.e = 0;
        this.f = 0;
    }

    translate(dx: number, dy: number) {
        this.transform(1, 0, 0, 1, dx, dy);
        return this;
    }

    /**
     * Apply transformation matrix to points.
     */
    apply(points: Vec2d | Vec2d[]): Vec2d[] {
        if (!Array.isArray(points)) {
            points = [points];
        }

        return points.map((p) => {
            const { x, y } = this.applyTo(p);
            return new Vec2d(x, y);
        });
    }

    /**
     * Apply transformation matrix to point.
     */
    private applyTo(point: Vec2d) {
        const { x, y } = point;

        return {
            x: x * this.a + y * this.c + this.e,
            y: x * this.b + y * this.d + this.f,
        };
    }

    /**
     * Update transformation matrix.
     */
    private transform(
        a1: number,
        b1: number,
        c1: number,
        d1: number,
        e1: number,
        f1: number
    ) {
        const { a, b, c, d, e, f } = this;

        this.a = a * a1 + c * b1;
        this.b = b * a1 + d * b1;
        this.c = a * c1 + c * d1;
        this.d = b * c1 + d * d1;
        this.e = a * e1 + c * f1 + e;
        this.f = b * e1 + d * f1 + f;

        return this;
    }
}

const isPoint = (maybePoint: any): maybePoint is Vec2d => maybePoint instanceof Vec2d;

const isPointArray = (maybePoint: any): maybePoint is Vec2d[] =>
    typeof maybePoint === "object" && isPoint(maybePoint[0]);

const isLatLng = (maybeLatLng: any): maybeLatLng is L.LatLng =>
    maybeLatLng instanceof L.LatLng ||
    (maybeLatLng.lng !== undefined && maybeLatLng.lat !== undefined);

const isLatLngArray = (maybeLatLngs: any): maybeLatLngs is L.LatLng[] =>
    Array.isArray(maybeLatLngs) && maybeLatLngs.every((latLng) => isLatLng(latLng));

const isNestedLatLngArray = (maybeLatLngs: any): maybeLatLngs is L.LatLng[][] =>
    Array.isArray(maybeLatLngs) && maybeLatLngs.every((ring) => isLatLngArray(ring));

const isPolygon = (maybePoint: any): maybePoint is L.Polygon =>
    maybePoint instanceof L.Polygon;
