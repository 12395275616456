import rewind from "@turf/rewind";
import L, * as leaflet from "leaflet";
import isClockwise from "turf-is-clockwise";
import { translateXY } from "./geometry/transforms";
import { Vec2d } from "./geometry/vec2d";

export const createHintMarkerAt = (
    location: leaflet.LatLng,
    markerOptions?: leaflet.MarkerOptions | null
): leaflet.Marker => {
    markerOptions = markerOptions || {};

    const defaults = {
        icon: leaflet.divIcon({
            className: "leaflet-marker-icon marker-icon",
        }),
    };

    const options = Object.assign(defaults, markerOptions);

    return leaflet.marker(location, options);
};

interface UnpackOptions {
    forceClockwise: boolean;
}

const defaultUnpackOptions: UnpackOptions = {
    forceClockwise: false,
};

/**
 * Unpack coordinates of feature.
 *
 * Leaflet's getLatLngs() can return a nested array. That structure is meant
 * for Polygons with holes, or MultiPolygons. Since we do not use these shapes,
 * manually unpacking the coords for each leaflet.Polygon or leaflet.Rectangle (which is a
 * subclass if leaflet.Polygon) is tedious.
 *
 * Ignores any holes in polygon.
 */
export const unpackCoords = (
    feature: leaflet.Polygon,
    options?: UnpackOptions
): leaflet.LatLng[] => {
    if (options) {
        options = { ...defaultUnpackOptions, ...options };
    } else {
        options = defaultUnpackOptions;
    }

    if (options.forceClockwise) {
        return getClockwiseCoordinates(feature);
    }

    return unpack(feature.getLatLngs());
};

/**
 * Recursively unpack nested coordinate structure.
 */
const unpack = (
    latlngs: leaflet.LatLng[] | leaflet.LatLng[][] | leaflet.LatLng[][][]
): leaflet.LatLng[] => {
    if (latlngs.length >= 1 && Array.isArray(latlngs[0])) {
        latlngs = unpack(latlngs[0]);
    }

    return [...latlngs] as leaflet.LatLng[];
};

/**
 * Returns polgyon's exterior ring as flat array and clockwise-oriented.
 *
 * TODO(c):
 *  - rename
 *  - check performance impact of this
 *  - find root cause that causes some polygons to be not clockwise-oriented
 *    when received from backend.
 */
function getClockwiseCoordinates(polygon: leaflet.Polygon): leaflet.LatLng[] {
    // turf requires geojson input
    const feature = polygon.toGeoJSON();

    if (isClockwise(feature.geometry.coordinates[0])) {
        return unpack(polygon.getLatLngs());
    }

    rewind(feature, { reverse: true, mutate: true });

    return feature.geometry.coordinates[0].map((p) =>
        leaflet.latLng({ lng: p[0], lat: p[1] })
    );
}

/**
 * Moves multiple objects relative to distance between two positions.
 */
export function moveFeaturesRelativeToMovement({
    previous,
    current,
    features,
}: {
    previous: L.LatLng;
    current: L.LatLng;
    features: L.Polygon[];
}) {
    const currentVec = new Vec2d(current.lng, current.lat);
    const previousVec = new Vec2d(previous.lng, previous.lat);
    const distanceVec = Vec2d.Sub(currentVec, previousVec);

    for (const feature of features) {
        translateXY(feature, distanceVec, { mutate: true });
    }
}

export const deg2rad = (deg: number): number => deg * (Math.PI / 180);
export const rad2deg = (rad: number): number => rad * (180 / Math.PI);

/**
 * Partition array based on predicate.
 *
 * Credits: https://gist.github.com/zachlysobey/71ac85046d0d533287ed85e1caa64660
 */
export function partition<T>(
    arr: Array<T>,
    predicate: (val: T) => boolean
): [Array<T>, Array<T>] {
    const partitioned: [Array<T>, Array<T>] = [[], []];
    arr.forEach((val: T) => {
        const partitionIndex: 0 | 1 = predicate(val) ? 0 : 1;
        partitioned[partitionIndex].push(val);
    });
    return partitioned;
}
