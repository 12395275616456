// Selectors to open / close modals on click.
const MODAL_CLOSE =
    ".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button:not(.keep-modal):not(.modal-trigger)";

/**
 * Register event handlers to open or close modals.
 */
export function registerModalHandlers() {
    document.body.addEventListener("click", (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (!target) {
            return;
        }

        // Close all active modals when clicking on any modal close trigger element.
        if (target.closest(MODAL_CLOSE)) {
            Modal.closeActive();
        }
    });

    // Close all modals on ESC
    //
    // TODO: should we merge this into bind-keys?
    document.addEventListener("keydown", (event) => {
        if (event.key == "Escape") {
            Modal.closeActive();
        }
    });
}

interface NewModalData {
    /**
     * Title to display in the modals header.
     */
    header: string;

    /**
     * Text to display in the modals body.
     */
    body: string;

    /**
     * Theme to style the modal.
     */
    theme?: string;

    /**
     * If true, modal can not be closed.
     */
    closable?: boolean;

    /**
     * URL to navigate to after confirming.
     */
    confirmUrl?: string;

    /**
     * Text to display on the confirm button.
     */
    confirmText?: string;

    /**
     * Text to display on the cancel button.
     */
    cancelText?: string;

    /**
     * Callback to run after user has confirmed.
     */
    onConfirm?: () => void | Promise<void>;
}

/**
 * Data to customize and exsting modal.
 *
 * Covers most of the configuration options when creating new modals with some
 * exceptions, such as theming.
 */
type CustomizeModalData = Partial<Omit<NewModalData, "theme" | "closable">>;

export class Modal {
    static selector = ".modal.is-active";

    /**
     * Show modal.
     */
    static show(elementOrId: string | HTMLElement, options: CustomizeModalData = {}) {
        const { body, header, confirmUrl, confirmText, cancelText } = options;

        let modal: HTMLElement;

        if (elementOrId instanceof HTMLElement) {
            modal = elementOrId;
        } else {
            const maybeModal = document.getElementById(elementOrId) as HTMLElement | null;

            if (maybeModal === null) {
                throw new Error(`Unable to find modal with id: "${elementOrId}"`);
            }

            modal = maybeModal;
        }

        const cardBody = modal.querySelector(".modal-card-body");
        const cardHeader = modal.querySelector(".modal-card-title");
        const confirmButton = modal.querySelector<HTMLAnchorElement>(".button.confirm");
        const cancelButton = modal.querySelector(".button.cancel");

        if (body && cardBody) {
            cardBody.innerHTML = body;
        }

        if (header && cardHeader) {
            cardHeader.innerHTML = header;
        }

        if (confirmButton) {
            if (confirmUrl) {
                confirmButton.href = confirmUrl;
            }

            if (confirmText) {
                confirmButton.innerHTML = confirmText;
            }

            confirmButton.addEventListener(
                "click",
                () => {
                    modal.dispatchEvent(new Event("modal:confirm"));

                    if (options.onConfirm) {
                        options.onConfirm();
                    }
                },
                {
                    once: true,
                }
            );
        }

        if (cancelButton && cancelText) {
            cancelButton.innerHTML = cancelText;
        }

        // For now, modals can not be stacked. Stacked modals open a trove of
        // new problems, e.g, how to identify the currently active modal when
        // submitting forms.
        Modal.closeActive();

        modal.classList.add("is-active");

        return modal;
    }

    /**
     * Create a modal from scratch.
     *
     * Modal becomes visible immediately when calling this method.
     *
     * @example
     * Render a modal from scratch:
     * ```
     * const modal = Modal.render({
     *  header: "Text in the header",
     *  body: "Some content",
     *  confirmText: "Confirm!",
     *  onConfirm: () => console.log("Confirmed!")
     * })
     *
     */
    static render(options: NewModalData): HTMLElement {
        const { header, body } = options;
        const theme = options.theme ?? "danger";
        const confirmText = options.confirmText ?? _("Ok");
        const cancelText = options.cancelText ?? _("Cancel");
        const confirmUrl = options.confirmUrl ?? "#";
        const closable = options.closable ?? true;

        // Because we already have a lot of pieces, we just add a "shell" modal to
        // the DOM and then let Modal.show handle the details.
        const html = `
            <div class="modal is-active" data-closable="${closable}">

            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head has-background-${theme}">
                    <p class="modal-card-title has-text-white">
                    </p>
                    <button class="delete" aria-label="close"></button>
                </header>

                <section class="modal-card-body">
                </section>

                <footer class="modal-card-foot">
                    <div>
                        <a href="${confirmUrl}" class="button is-${theme} confirm" role="button"></a>
                        <a class="button cancel" role="button"></a>
                    </div>
                </footer>
            </div>
        </div>
        `;

        const placeholder = document.createElement("div");
        placeholder.innerHTML = html;

        const modal = placeholder.firstElementChild as HTMLElement;
        document.body.prepend(modal);

        const opts: CustomizeModalData = {
            header: header,
            body: body,
            confirmText: confirmText,
            cancelText: cancelText,
            confirmUrl: confirmUrl,
            onConfirm: options.onConfirm,
        };

        Modal.show(modal, opts);

        // Remove dynamic modals from DOM on close.
        modal.addEventListener("modal:close", () => {
            // NOTE: Does not wait for any CSS transition to be finished. At
            // the moment, modal closing has no transition. But if you add
            // some, you want to wait for that to finish here.
            document.body.removeChild(modal);
        });

        return modal;
    }

    /**
     * Close currently active modal(s).
     *
     * Ideally, only one modal is active at a given time. However, modals might
     * open other modals, leading to multiple modals being active for a short
     * amount of time.
     *
     * To prevent your modal from being closed by a user, supply data-closable=false.
     *
     * Fires a "modal:close" event on every closed modal.
     */
    static closeActive() {
        const elements = document.querySelectorAll<HTMLElement>(
            ".modal.is-active:not([data-closable=false])"
        );

        elements.forEach((element) => {
            element.classList.remove("is-active");
            element.dispatchEvent(new Event("modal:close"));
        });
    }
}
