{
    "Types": "Standardtypen",
    "Villas": "Punkthaus",
    "Misc.": "Unkategorisiert",
    "Total": "Gesamt",
    "No data available.": "Keine Daten verfügbar.",
    "This field is required.": "Dies ist ein Pflichtfeld.",
    "File does not have an approved extension: {extensions}": "Hochgeladene Datei hat ein falsches Format. Erlaubt sind folgende Dateiendungen: {extensions}",
    "Building Area": "Baufeld",
    "Housing units": "WE",
    "No building areas available.": "Keine Baufelder gefunden.",
    "BGF": "",
    "Ground Area": "Grundfläche",
    "NUF(R)": "",
    "NUF(S)": "",
    "Rentable space (NUF(S) 25%)": "Vermietbare Fläche (25% NUF(S))",
    "Rentable space (NUF(S) 50%)": "Vermietbare Fläche (50% NUF(S))",
    "Property": "Grundstück",
    "Property Area (m²)": "Grundstücksfläche (m²)",
    "Surface Area (m²)": "Oberfläche (m²)",
    "Floor Area (m²)": "Geschossfläche (m²)",
    "Max. GRZ": "",
    "Max. GFZ": "",
    "Max. Floors": "Max. Geschosse",
    "GRZ": "",
    "GFZ": "",
    "No properties available.": "Keine Grundstücke gefunden.",
    "Property Area": "Grundstücksfläche",
    "Covered Area": "Grundfläche",
    "Förderfähigkeit (m²)": "",
    "Förderfähigkeit (%)": "",
    "Split": "",
    "Quantity": "",
    "No buildings available.": "Keine Gebäude gefunden.",
    "${number} BR. Apt.": "${number}-Zimmer-WE",
    "Custom type": "Sonderbau",
    "Type": "Gebäudetyp",
    "Number": "Anzahl",
    "Area (m²)": "Fläche (m²)",
    "Floors": "Geschosse",
    "Total Area (m²)": "Gesamtfläche (m²)",
    "Total housing units": "Anzahl Wohneinheiten",
    "Förderfähig (%)": "",
    "Address": "Adresse",
    "Town": "Ort",
    "Federate State": "Bundesland",
    "Total Price": "Gesamtkaufpreis",
    "Property Total": "Grundstücksfläche Gesamt",
    "BGF (m²)": "BGF (m²)",
    "NUF(R) (m²)": "",
    "Number of buildings": "Gebäude",
    "Number of building types": "Typen",
    "Price per m²-NUF(R) (€)": "Kaufpreis pro m²-NUF(R) (€)",
    "fixed area": "vorgegebene Grundstücksfläche",
    "Property exceeds": "Grundstück übersteigt",
    " and ": " und ",
    "Exceeds": "Übersteigt",
    "${metric} by ${diff}": "die ${metric} um ${diff}",
    "Some Column": "",
    "Some Value": "",
    "Identifier": "Bezeichnung",
    "NOKERA Product Catalogue": "NOKERA Produktkatalog",
    "Catalogue file": "Datei Produktkatalog",
    "Catalogue must be a .zip archive.": "Produktkatalog muss als ZIP-Archiv hochgeladen werden.",
    "Failed to verify catalogue.": "Überprüfung des Katalogs fehlgeschlagen.",
    "Successfully imported catalogue.": "Katalog erfolgreich importiert.",
    "Saved changes to the catalogue.": "Änderungen am Katalog gespeichert.",
    "All": "Alle",
    "Flächenaufstellung": "",
    "Wohnungseinheiten-Split": "",
    "Förderfähigkeit": "",
    "Parking spaces per unit": "Zusätzliche Parkplätze pro Einheit",
    "Unit size": "Grösse pro Einheit",
    "Max. units": "Max. Einheiten",
    "Min. units": "Min. Einheiten",
    "Default units": "Standard Einheiten",
    "Core spaces": "Parkplätze im Kern",
    "Core spaces (ground floor)": "Parkplätze im Kern (EG)",
    "Core width": "Breite Kern",
    "Core height": "Höhe Kern",
    "Floor Type": "Geschossart",
    "NUF (R) (m²)": "",
    "NUF (S) (m²)": "",
    "${rooms} BR Apt.": "${rooms} Zi.-Whg.",
    "An email has been sent to the requested account with further information. If you do not receive an email then please confirm you have entered the correct email address.": "Es wurde eine E-Mail mit weiteren Informationen an das angeforderte Konto gesendet. Sollten Sie keine E-Mail erhalten, bestätigen Sie bitte, dass Sie die richtige E-Mail-Adresse eingegeben haben.",
    "Email address": "E-Mail-Adresse",
    "Password": "Passwort",
    "Login failed.": "Anmeldung fehlgeschlagen.",
    "Archive project": "Archivieren",
    "Do you really want to archive the project \"${name}\"? You can restore the project at any time from the archive.": "Möchten Sie das Projekt \"${name}\" archivieren? Sie können das Projekt jederzeit aus dem Archiv wiederherstellen. ",
    "Delete project": "Projekt löschen",
    "Do you really want to delete the project \"${name}\"?This action can not be undone. If you still need the project, consider archiving it.": "Möchten Sie das Projekt \"${name}\" endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden. Falls Sie das Projekt in Zukunft noch benötigen, können Sie es archivieren.",
    "Successfully created project \"${title}\".": "Neues Projekt \"${title}\" erstellt.",
    "Project \"${title}\" successfully archived.": "Projekt \"${title}\" erfolgreich archiviert.",
    "Project \"${title}\" successfully restored.": "Projekt \"${title}\" erfolgreich wiederhergestellt.",
    "Project \"${title}\" successfully deleted.": "Projekt \"${title}\" erfolgreich gelöscht.",
    "Email": "E-Mail",
    "New Password": "Neues Passwort",
    "Confirm New Password": "Neues Passwort bestätigen",
    "Password changed": "Passwort geändert",
    "success": "Erfolg",
    "There was a problem with your submission. Errors have been highlighted below.": "Es gab ein Problem mit Ihrer Übermittlung. Die Fehler wurden unten hervorgehoben",
    "Password must have minimal length of 8 characters, contain one upper case letter, one lower case letter, one digit and one special character.": "Das Passwort muss mindestens 8 Zeichen lang sein und einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten.",
    "User \"${email}\" already exists.": "User \"${email}\" existiert bereits.",
    "Domain \"${domain}\" is not allowed. Must be one of the following: ${allowed}": "Domäne \"${domain}\" ist nicht erlaubt. Es dürfen nur Empfänger der folgenden Organisationen eingeladen werden: ${allowed}",
    "An invite e-mail has been sent to \"${email}\".": "Es wurde eine E-Mail an \"${email}\" mit weiteren Informationen gesendet.",
    "Fixed Area": "Vorgegebene Grundstücksfläche",
    "Enter a fixed area for this property.": "Hier können Sie eine vorgegebene Fläche für das Grundstück eintragen. Die effektive, eingezeichnete Fläche wird dadurch ignoriert.",
    "Property has no limits.": "Grundstück hat keine maximale GRZ / GFZ",
    "Changes to the project were saved.": "Änderungen am Projekt wurden gespeichert.",
    "Successfully rescaled development plan to 1:${scale}": "Massstab auf 1:${scale} angepasst",
    "Variants": "",
    "Redact total price": "Report ohne Kaufpreis erstellen",
    "Total price": "Gesamtkaufpreis",
    "Scale": "Massstab",
    "Spacing area factor": "Faktor Abstandsfläche",
    "Product catalogue": "Produktkatalog",
    "Development Plan File (PDF)": "Datei Bebauungsplan",
    "Document must be a PDF.": "Datei muss ein PDF sein.",
    "Number of floors": "Anzahl Geschosse",
    "Garage units": "Zusätzliche Parkhaus Module",
    "Title": "Titel",
    "e.g. Courtyard": "",
    "Copy building areas from current layout.": "Baufelder übernehmen.",
    "Copy building from current layout.": "Gebäude übernehmen.",
    "${number} funded": "${number} gefördert",
    "${number} Flats": "${number} Wohnungen",
    "Federate State: ${state}": "Bundesland: ${state}",
    "Strategy": "Strategie",
    "Variant to overwrite": "",
    "Overwrite current variant": "Aktuelle Variante überschreiben",
    "Latch Depth": "Riegeltiefe",
    "Center variant": "Bebauung zentrieren",
    "Available building types": "Gebäudetypen",
    "Min. Floors": "Min. Geschosse",
    "Notes": "Notizen",
    "Zusätzliche Notizen zu diesem Projekt.": "",
    "You have to create one or more building areas first!": "Keine Baufelder gefunden!",
    "Generated variant for '${name}' with ${amount} buildings.": "Baufeld ${name} mit ${amount} Gebäuden beplant.",
    "Could not construct a variant for building area '${name}' with respect to its parameters (GRZ: ${max_grz}%).": "Für das Baufeld '${name}' konnte kein Layout erstellt werden ohne die Limiten zu übersteigen (GRZ: ${max_grz}%).",
    "Building area '${name}' is not a rectangle!": "Baufeld '${name}' ist kein Rechteck!",
    "Building area '${name}' can not be planned because the minimum number of floors exceeds the property's maximum": "Baufeld ${name} kann nicht beplant werden: Die mind. Anzahl Geschosse übersteigt die maximale Anzahl Geschosse des Grundstücks!",
    "Building area '${name}' can not be planned because the property has no limits (GRZ / GFZ) set.": "Baufeld ${name} kann nicht beplant werden: Das Grundstück hat keine maximale GRZ oder GFZ konfiguriert.",
    "Variant '${name}' deleted.": "Variante '${name}' gelöscht.",
    "Profile": "Profil",
    "Personal information & settings": "Persönliche Angaben & Einstellungen",
    "Name": "",
    "E-Mail-Address": "E-Mail-Adresse",
    "PowerBI": "",
    "Import data into PowerBI": "Daten in PowerBI importieren",
    "Activate PowerBI access": "PowerBI Zugang aktivieren",
    "Deactivate": "Deaktivieren",
    "Follow the step-by-step instructions below to automatically import data into PowerBI.": "Folgen Sie untenstehender Schritt-für-Schritt Anleitung, um Daten automatisch in PowerBI zu importieren.",
    "Instructions": "Anleitung",
    "Open PowerBI Desktop": "Öffnen Sie PowerBI Desktop",
    "Navigate to Data → Get Data → Web": "Navigieren sie zu Daten → Daten abrufen → Web",
    "From Web": "Aus dem Web",
    "Advanced": "Weitere",
    "In the new window ${window} select the option ${advanced}": "Im neuen Fenster ${window} die Option ${advanced} auswählen",
    "URL parts": "URL-Teile",
    "Under ${url-parts} paste the following URL:": "Unter ${url-parts} fügen Sie untenstehende URL ein:",
    "HTTP request header parameters (optional)": "Parameter des HTTP-Anforderungsheaders (optional)",
    "Navigate down to ${http-headers}": "Navigieren Sie nach ganz unten zu ${http-headers}",
    "Write ${auth-http-header} into the first input field below": "Darunter tragen Sie im ersten Feld ${auth-http-header} ein",
    "As value you enter the following access code:": "Als Wert tragen Sie folgende Zugangsdaten ein:",
    "Click ${ok} to add the new data source": "Klicken Sie auf ${ok} um die Datenquelle hinzuzufügen",
    "Done! Now you can use the new data source.": "Fertig! Nun können Sie die neue Datenquelle verwenden.",
    "Change Password": "Passwort ändern",
    "Password Retrieval": "Account aktivieren",
    "Submit": "Absenden",
    "Sign in": "Anmelden",
    "→ Forgot your password / Activate account": "→ Passwort vergessen / Account aktivieren ",
    "Edit ${title}": "${title} bearbeiten",
    "a new catalogue": "neue Version des Produktkatalogs hochladen",
    "At the moment, you can only edit a catalogues name. For larger changes, upload ${link}": "Derzeit können Sie lediglich die Bezeichnung des Katalogs ändern. Wenn Sie größere Anpassungen vornehmen möchten, wie beispielsweise einen neuen Gebäudetypen hinzufügen, müssen Sie eine ${link}.",
    "<dynamic element>": "",
    "Save changes": "Änderungen speichern",
    "All catalogues": "Alle Kataloge",
    "Catalogue: ${title}": "Katalog: ${title}",
    "Edit": "Bearbeiten",
    "Common configuration": "Allgemeine Daten",
    "Floor heights": "Geschosshöhen",
    "Height": "Höhe",
    "Balcony": "Balkon",
    "Balcony Type": "Balkontyp",
    "Width": "Breite",
    "Standard Balcony": "Standardbalkon",
    "Building Types ${amount}": "Gebäudetypen ${amount}",
    "Building type": "Gebäudetyp",
    "Category": "Kategorie",
    "Bundesland": "",
    "Special Building Types ${amount}": "Spezielle Gebäudetypen ${amount}",
    "Attachments ${amount}": "Dateianhänge ${amount}",
    "Any file that has been attached to the catalogue can be found here.": "Hier finden Sie alle Dateien, die dem Katalog beigefügt wurden.",
    "Filename": "Dateiname",
    "Filetype": "Dateityp",
    "Building type: ${title}": "Gebäudetyp: ${title}",
    "Preview": "Vorschau",
    "Parking configuration": "Konfiguration Parkhaus",
    "Configuration": "Konfiguration",
    "Value": "Wert",
    "Custom building configuration": "Sonderbau Konfiguration",
    "There are no predefined values for the special type in terms of usable space or residential units. In projects, these values are estimated using the heuristics below.": "Für den Sondertyp existieren keine vordefinierten Werte bezüglich Nutzungsflächen oder Wohneinheiten. In Projekten werden diese Werte mittels untenstehenden Heuristiken geschätzt.",
    "Factor NUF(R)": "Faktor NUF(R)",
    "NUF(R) is calculated from this factor × building": "NUF(R) berechnet sich aus diesem Faktor × Gebäude",
    "Factor NUF(S)": "Faktor NUF(S)",
    "NUF(S) is calculated from this factor × building": "NUF(S) berechnet sich aus diesem Faktor × Gebäude",
    "Average apartment size": "Durchschnittliche Wohnungsgrösse",
    "Used to estimate the number of housing units in a custom building": "Wird verwendet um die Anzahl Wohneinheiten in einem Sonderbau zu schätzen",
    "Catalogue": "Katalog",
    "Add new catalogue": "Neuen Katalog hinzufügen",
    "Projects": "Projekte",
    "Building Types": "Gebäudetypen",
    "Attachments": "Dateianhänge",
    "Extended": "Erweitert",
    "Open": "Öffnen",
    "catalogue.upload.info": "Der Produktkatalog kann als ZIP-Archiv hochgeladen werden. In diesem Archiv muss sich eine gültige Katalogdatei befinden die dem ${schema_link} entspricht. Zusätzlich können Sie dem Katalog auch optionale Anhänge anfügen indem Sie die Dateien in einen Ordner namens “assets” im Archiv platzieren.  ",
    "Upload catalogue": "Katalog hochladen",
    "Validation Errors": "Fehlerhafte Daten",
    "Something is not right with the provided catalogue data:": "Die hochgeladene Datei enthält möglicherweise invalide Daten oder entspricht nicht dem Schema. Untenstehend finden Sie eine umfassende Fehlermeldung:",
    "Do you really want to delete this project?": "Möchten Sie dieses Projekt endgültig löschen?",
    "Cancel": "Abbrechen",
    "New project": "Neues Projekt",
    "Kürzlich bearbeitete Projekte": "",
    "Active&nbsp;${amount}": "Aktiv&nbsp;${amount}",
    "Archive&nbsp;${amount}": "Archiv&nbsp;${amount}",
    "Last modified at": "Zuletzt bearbeitet am",
    "Created at": "Erstellt am",
    "Archive": "Archivieren",
    "Unarchive project": "Projekt aus Archiv wiederherstellen",
    "Delete": "Löschen",
    "Add project": "Neues Projekt erstellen",
    "choose from folder.": "wählen Sie eine Datei vom System aus.",
    "Drop a file here or ${from-folder}": "Ziehen Sie eine Datei hierher oder ${from-folder}",
    "<dynamic element> <dynamic element>": "",
    "Development plan ${filename} selected": "Bebauungsplan ${filename} ausgewählt",
    "Create": "Projekt erstellen",
    "Edit project": "Projekt bearbeiten",
    "Statistics": "Übersicht",
    "Create report": "Report erstellen",
    "Generate layout": "Variante generieren",
    "Settings": "Einstellungen",
    "Back to projects": "Zur Projektübersicht zurückkehren",
    "Download report": "Report erstellen",
    "Below, you can select the variants to report. If you want to include all variants, leave the selection as-is.": "Erstellt ein PDF-Report für dieses Projekt. Falls gewünscht können Sie die Varianten, die im Report enthalten sein werden, unten auswählen.",
    "Select variants ...": "Varianten auswählen ...",
    "Advanced settings": "Erweiterte Einstellungen",
    "Generate": "Report erzeugen",
    "Project summary": "Projektübersicht",
    "Housing units (WE) per building area": "Wohneinheiten (WE) pro Baufeld",
    "Overview Splits": "Übersicht WE-Splits",
    "Overview Building Areas": "Flächenübersicht pro Baufeld",
    "Overview Funding": "Übersicht Förderfähigkeit",
    "Overview Utilization": "Übersicht Auslastung",
    "Buildings": "Gebäude",
    "Summary": "Zusammenfassung",
    "Total number of flats": "Total Wohnungen",
    "Amount of building types": "Anzahl Gebäudetypen",
    "Properties": "Grundstücke",
    "Copy": "Auswahl kopieren",
    "Ctrl + C": "Strg + C",
    "Paste here": "Hier einfügen",
    "Ctrl + V": "Strg + V",
    "Create new layout": "Neue Variante erstellen",
    "Create layout": "Variante erstellen",
    "Generate a layout automatically.": "Automatische Planung.",
    "Choose a strategy from the selection below. If you want to overwrite the current layout, make sure to check the checkbox underneath.": "Wählen Sie eine Strategie aus um eine Variante zu generieren. Um die aktuelle Variante zu überschreiben, wählen Sie das Kästchen darunter an.",
    "Select types ...": "Gebäudetypen auswählen ...",
    "Select all": "Alle auswählen",
    "Generating report ...": "Report wird erstellt ...",
    "Once your report is ready, this page will refresh itself automatically.": "Diese Seite aktualisiert sich automatisch sobald der Report erstellt wurde.",
    "Change scale?": "Massstab ändern?",
    "deleted irrevocably": "unwiederruflich gelöscht",
    "All layouts and buildings will be ${warning}. Properties will remain.": "Alle Varianten und Gebäude werden ${warning}. Grundstücke bleiben unverändert.",
    "Adjust scale": "Massstab ändern",
    "Delete building": "Gebäude löschen",
    "Delete building(s).": "Gebäude löschen.",
    "Keybindings": "Tastenkombinationen",
    "Key": "Tastenkombination",
    "Help": "Beschreibung",
    "Generating layout ...": "Generiere Variante ...",
    "Once your layout is ready, this page will refresh itself automatically.": "Diese Seite aktualisiert sich automatisch sobald die Variante erzeugt wurde.",
    "Delete Layout.": "Variante löschen",
    "Do you really want to delete this layout?": "Möchten Sie diese Variante wirklich löschen?",
    "Start by drawing the a building area on the plan.": "Zeichnen Sie zuerst das Baufeld auf dem Plan ein.",
    "Form": "",
    "Rectangle": "Rechteck",
    "Freehand": "Freihand",
    "Add": "Hinzufügen",
    "Save": "Speichern",
    "Building Areas": "Baufelder",
    "Add property": "Grundstück hinzufügen",
    "Property Total: ${total}": "Grundstücksfläche Gesamt: ${total}",
    "Add building area": "Baufeld hinzufügen",
    "Start by drawing a property on the plan.": "Zeichnen Sie zuerst das Grundstück auf dem Plan ein.",
    "Edit variant": "Variante bearbeiten",
    "Delete this variant.": "Diese Variante löschen.",
    "Adjusting the scale will delete all layouts.": "Wenn Sie den Massstab ändern, werden alle Varianten unwiederruflich zurückgesetzt.",
    "You are about to update multiple buildings.": "Diese Änderung betrifft mehrere Gebäude.",
    "Team": "Team",
    "Add member": "Teammitglied hinzufügen",
    "No warnings": "Keine Warnungen",
    "No limits exceeded.": "Es werden keine Limiten übertreten.",
    "Continue": "Fortfahren",
    "Warning: Limits exceeded.": "Limiten überschritten",
    "Multiple properties exceed their limits:": "Mehrere Grundstücke haben ihre Limiten überschritten:",
    "Copy to clipboard": "In Zwischenablage kopieren",
    "Log out": "Abmelden",
    "Move object on map": "Element verschieben",
    "Rotate object on map": "Element rotieren",
    "Edit object on map": "Element bearbeiten",
    "Edit metadata": "Metadaten bearbeiten",
    "Delete object": "Element löschen",
    "Delete property": "Grundstück löschen",
    "Do you really want to delete this property?": "Möchten Sie dieses Grundstück wirklich löschen?",
    "Delete building area": "Baufeld löschen",
    "Do you really want to delete this building area?": "Möchten Sie dieses Baufeld wirklich löschen?",
    "Total number of flats: ${total_number_of_flats}": "Total Wohnungen: ${total_number_of_flats}",
    "Ground Floor": "Erdgeschoss",
    "Regular Floor": "Regelgeschoss",
    "Attic Floor": "Dachgeschoss",
    "Edge type": "Ecktyp",
    "Regular type": "Regulärer Gebäudetyp",
    "Villa type": "Punkthaus",
    "Parking type": "Parkhaus",
    "Misc type": "Unkategorisiert",
    "All selected": "Alle ausgewählt",
    "Angle": "Winkel",
    "Balconies": "Balkone",
    "Can not paste feature: ": "Kopierte Gebäude können hier nicht eingefügt werden: ",
    "Cancel currently active tool.": "Aktives Werkzeug abbrechen.",
    "Click to leave editing mode": "Klicken Sie, um den Bearbeitungsmodus zu verlassen",
    "Confirm": "Bestätigen",
    "Copy elements from plan.": "Gebäude vom Plan kopieren.",
    "Could not copy content to the clipboard. Please copy the content manually.": "Der Inhalt konnte nicht in die Zwischenablage kopiert werden. Bitte kopieren Sie den Inhalt manuell.",
    "Delete currently selected buildings": "Ausgewählte Gebäude löschen",
    "Delete currently selected buildings.": "Ausgewählte Gebäude löschen.",
    "Delete object(s).": "Gebäude löschen.",
    "Display alignment lines": "Fluchten anzeigen",
    "Display balconies": "Balkone anzeigen",
    "Display spacings": "Abstandsflächen anzeigen",
    "Distance": "Distanz",
    "Do you really want to delete these {{ number }} buildings?": "Möchten Sie wirklich diese {{ number }} Gebäude löschen? ",
    "Do you really want to delete this building?": "Möchten Sie dieses Gebäude wirklich löschen?",
    "Drag / move objects": "Objekte ziehen / verschieben",
    "Drag buildings": "Gebäude bewegen",
    "Duplicate buildings": "Ausgewählte Gebäude klonen",
    "Duplicate objects.": "Ausgewählte Objekte duplizieren.",
    "Edit custom building": "Sonderbau bearbeiten",
    "Edit geometry of an object.": "",
    "Failed to undo last command.": "Letzte Änderung rückgangig machen fehlgeschlagen.",
    "Features use different catalogue and scale.": "Gebäude stammen von unterschiedlichen Katalogen und haben unterschiedliche Massstäbe.",
    "Features use different catalogues.": "Gebäudetypen stammen aus unterschiedlichen Produktkatalogen.",
    "Features use different scales.": "Gebäude haben unterschiedliche Massstäbe.",
    "Measure distance": "Distanz messen",
    "Measure distances on the map.": "Distanzen auf der Karte messen.",
    "Move buildings": "Gebäude verschieben",
    "Move objects.": "Objekte verschieben.",
    "Multiple parkings found - please select one to resize.": "Mehrere Parkhäuser auf dem Plan. Bitte wählen Sie das Parkhaus aus, welches sie verändern möchten.",
    "No buildings selected. Please select the buildings you want to delete.": "Keine Gebäude ausgewählt. Bitte wählen Sie Gebäude aus, welche sie löschen möchten.",
    "No commands to undo.": "Keine Änderungen vorhanden zum rückgängig machen.",
    "No custom building selected.": "Kein Sonderbau ausgewählt.",
    "No data available in table.": "Diese Tabelle ist leer.",
    "No matching records found.": "Keine passenden Einträge gefunden.",
    "No parking found.": "Kein Parkhaus vorhanden.",
    "Nothing to copy.": "",
    "Nothing to undo.": "Keine Änderung vorhanden.",
    "Ok": "Ok",
    "Paste buildings": "Gebäude einfügen",
    "Paste elements to plan.": "Kopierte Gebäude einfügen.",
    "Please select building(s) to rotate.": "Bitte wählen Sie die Gebäude aus, die Sie rotieren möchten.",
    "Resize parking": "Grössenänderung Parkhaus",
    "Resize parking.": "Parkhaus vergrössern / verkleinern",
    "Rotate building": "Gebäude rotieren",
    "Rotate building area.": "",
    "Rotate objects.": "Objekte rotieren.",
    "Rotate selected items.": "Ausgewählte Objekte rotieren.",
    "Select buildings": "Gebäude auswählen",
    "Select objects": "Objekte auswählen",
    "Selected: {{ number }}": "Ausgewählt: {{ number }}",
    "Show this help text.": "Diese Hilfe anzeigen.",
    "Snap / move objects around.": "Objekte per Snap verschieben.",
    "Spacing areas": "Abstandsflächen",
    "Successfully copied to the clipboard.": "Erfolgreich in die Zwischenablage kopiert.",
    "Unable to retrieve form content from the server - please try again.": "Formular konnte nicht geladen werden - bitte versuchen Sie es erneut.",
    "Unable to update project settings.": "",
    "Undo last action (if available).": "Letzte Änderung rückgangig machen.",
    "Undo last change": "Letzte Änderung rückgängig machen",
    "Undo last command: ": "Rückgangig machen: ",
    "Unexpected error while submitting this form.": "Formular konnte nicht übermittelt werden: Es ist ein unerwarteter Fehler aufgetreten."
}