import { unpackCoords } from "@potat/plan/helpers";
import * as Sentry from "@sentry/browser";
import { Polygon } from "leaflet";

export type NotificationTypes = "success" | "info" | "warning" | "danger";

export function notify(message: string, type: NotificationTypes, delay?: number): void {
    const delayValue = delay || 3000;

    const template = `
        <div
            data-stimulus-controller="notification"
            class="notification is-${type}"
            data-notification-delay-value="${delayValue}"

            data-transition-enter-from="hidden"
            data-transition-enter-to="show"
            data-transition-leave-from="show"
            data-transition-leave-to="leave">

            <button class="delete" data-stimulus-action="notification#hide"></button>
            <p>${message}</p>
        </div>
    `;

    const element = document.createElement("div");
    element.innerHTML = template;

    // Unwrap the notification from the temporary div to avoid nested divs.
    const notification = element.firstElementChild;

    if (!notification) {
        throw new Error("Unable to unpack notification from element.");
    }

    document.querySelector(".notifications")?.prepend(notification);
}

export function captureException(error: Error | string): void {
    if (typeof error === "string") {
        error = new Error(error);
    }

    console.error(error);

    Sentry.captureException(error);
}

export function makeid(length: number): string {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function withinBounds(area: Polygon, shape: Polygon, requiredEdges = 1): boolean {
    const bounds = area.getBounds();
    const edgesInBounds = unpackCoords(shape).filter((edge) => bounds.contains(edge));
    return edgesInBounds.length >= requiredEdges;
}
