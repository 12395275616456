/**
 * Attach a file to a <form> using drag & drop.
 *
 * Users can simple drop a file from their systems file explorer onto the drop
 * zone. Also offers users to pick a file with the file dialog as a fallback.
 *
 * Assumes dropzone to be inside a <form> with a file input. This controller
 * does not process anything about the dropped file, it simply forwards it to
 * the file input.
 *
 * The controller can be in two states: Waiting for the user to choose a file
 * or displaying the selection with an option to change the file (i.e., return
 * to choosing a file).
 */
import { Controller } from "@hotwired/stimulus";

type State = "choose" | "selected";
type DropEvent = CustomEvent & { dataTransfer?: DataTransfer };

export class FileDropController extends Controller<HTMLElement> {
    form: HTMLFormElement;
    fileInput: HTMLInputElement;

    static values = { state: { type: String, default: "choose" } };
    declare stateValue: State;

    static targets = ["stateInitial", "stateSuccess", "filename"];
    declare stateInitialTarget: HTMLElement;
    declare stateSuccessTarget: HTMLElement;
    declare filenameTarget: HTMLElement;
    declare hasFilenameTarget: HTMLElement;

    initialize(): void {
        const form = this.element.closest("form");
        if (!form) {
            throw new Error("Not inside a <form>!");
        }

        const fileInput = form.querySelector("input[type=file]");
        if (!fileInput) {
            throw new Error('No <input type="file"> found inside form.!');
        }

        this.form = form;
        this.fileInput = fileInput as HTMLInputElement;

        // Prevent dragover defaults to allow dropzone to receive drop event.
        this.element.addEventListener("dragover", (event) => {
            event.preventDefault();
        });

        // If the user has chosen a file from the file system dialog, update
        // the dropzone accordingly.
        this.fileInput.addEventListener("change", () => {
            this.stateValue = "selected";
            this.showDropzone();
        });
    }

    dropped(event: DropEvent) {
        event.preventDefault();

        this.unhighlight();

        if (event.dataTransfer) {
            this.fileInput.files = event.dataTransfer.files;
            this.stateValue = "selected";
            this.showDropzone();
        }
    }

    highlight() {
        this.element.classList.add("active");
    }

    unhighlight() {
        this.element.classList.remove("active");
    }

    clear() {
        this.stateValue = "choose";
        this.showDropzone();
    }

    openFileDialog() {
        this.fileInput.click();
    }

    showDropzone() {
        const showInitial = this.stateValue === "choose";

        // visibility: hidden vs is-hidden vs display
        this.stateInitialTarget.style.display = showInitial ? "flex" : "none";
        this.stateSuccessTarget.style.display = !showInitial ? "flex" : "none";

        if (showInitial) {
            this.fileInput.value = "";
        } else if (this.hasFilenameTarget) {
            const filename = this.fileInput.files![0].name;
            this.filenameTarget.textContent = filename;
        }
    }
}
