import { Controller } from "@hotwired/stimulus";

/**
 * Tab navigation.
 *
 * https://bulma.io/documentation/components/tabs/
 */
export class TabsController extends Controller {
    static targets = ["tab", "content"];

    tabTargets: HTMLElement[];
    contentTargets: HTMLElement[];

    select(event: MouseEvent) {
        const selectedTab = event.currentTarget as HTMLElement;

        const targetContent = this.contentTargets.find(
            (t) => t.id === selectedTab.dataset.tabTargetId
        );

        if (!targetContent) {
            console.error(
                `Unable to find tab target with id: "${selectedTab.dataset.tabTargetId}"`
            );

            return;
        }

        // Hide all ...
        this.tabTargets.map((t) => t.classList.remove("is-active"));
        this.contentTargets.map((t) => t.classList.add("is-hidden"));

        // ... except the target
        selectedTab.classList.add("is-active");
        targetContent.classList.remove("is-hidden");
    }
}
