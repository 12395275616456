import { Controller } from "@hotwired/stimulus";
import { notify } from "@potat/ui/utils";

/**
 * Copy custom content into the user's system clipboard.
 *
 * Controller lets you specify an element as source and and a trigger. When
 * copying to clipboard, the controller will copy the `innerText` property.
 *
 * Example:
 *
 *  <div data-stimulus-controller="clipboard">
 *   <pre data-clipboard-target="source">
 *       Some Code
 *   </pre>
 *
 *   <button data-stimulus-action="clipboard#copy">
 *      Copy the code!
 *   </button>
 *
 *  </div>
 *
 *
 * NOTE: To support copying other properties, you could parametrize this
 * controller with Stimulus values: https://stimulus.hotwired.dev/reference/values
 *
 */
export class ClipboardController extends Controller<HTMLElement> {
    static targets = ["source"];

    declare readonly hasSourceTarget: boolean;
    declare readonly sourceTarget: HTMLPreElement;

    connect(): void {
        if (!this.hasSourceTarget) {
            throw new Error("ClipboardController requires a source target!");
        }
    }

    copy() {
        const toCopy = this.sourceTarget.textContent;

        if (toCopy === null) {
            notify(_("Nothing to copy."), "danger");
            return;
        }

        try {
            navigator.clipboard.writeText(toCopy);
            notify(_("Successfully copied to the clipboard."), "success");
        } catch {
            notify(
                _(
                    "Could not copy content to the clipboard. Please copy the content manually."
                ),
                "danger"
            );
        }
    }
}
